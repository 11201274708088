import React, { useEffect, useRef } from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import styled from 'styled-components';
import { srConfig } from '@config';
import sr from '@utils/sr';
import { usePrefersReducedMotion } from '@hooks';

const StyledAboutSection = styled.section`
  max-width: 900px;

  .inner {
    display: grid;
    grid-template-columns: 3fr 2fr;
    grid-gap: 50px;

    @media (max-width: 768px) {
      display: block;
    }
  }
`;
const StyledText = styled.div`
  ul.skills-list {
    display: grid;
    grid-template-columns: repeat(2, minmax(140px, 200px));
    grid-gap: 0 10px;
    padding: 0;
    margin: 20px 0 0 0;
    overflow: hidden;
    list-style: none;

    li {
      position: relative;
      margin-bottom: 10px;
      padding-left: 20px;
      font-family: var(--font-mono);
      font-size: var(--fz-xs);

      &:before {
        content: '▹';
        position: absolute;
        left: 0;
        color: var(--green);
        font-size: var(--fz-sm);
        line-height: 12px;
      }
    }
  }
`;

const StyledPic = styled.div`
  position: right;
  max-width: 300px;

  @media (max-width: 768px) {
    margin: 50px auto 0;
    width: 70%;
  }

  .wrapper {
    ${({ theme }) => theme.mixins.boxShadow};
    display: block;
    position: relative;
    width: 100%;
    border-radius: var(--border-radius);
    background-color: var(--green);

    &:hover,
    &:focus {
      outline: 0;
      transform: translate(-4px, -4px);

      &:after {
        transform: translate(8px, 8px);
      }

      .img {
        filter: none;
        mix-blend-mode: normal;
      }
    }

    // .img {
    //   position: relative;
    //   border-radius: var(--border-radius);
    //   mix-blend-mode: multiply;
    //   filter: grayscale(100%) contrast(1);
    //   transition: var(--transition);
    // }

    &:before,
    &:after {
      content: '';
      display: block;
      position: absolute;
      width: 100%;
      height: 100%;
      border-radius: var(--border-radius);
      transition: var(--transition);
    }

    &:before {
      top: 0;
      left: 0;
      background-color: var(--navy);
      mix-blend-mode: screen;
    }

    &:after {
      border: 2px solid var(--green);
      top: 14px;
      left: 14px;
      z-index: -1;
    }
  }
`;

const GalleryWrapper = styled.div`
  display: flex;
  flex-wrap: wrap; /* Ensures wrapping on small screens */
  gap: 20px; /* Adjust spacing between images */
  justify-content: left; /* Centers images in a row */
  align-items: left;

  @media (max-width: 768px) {
    flex-direction: row; /* Keeps images in a row on mobile */
    gap: 10px; /* Reduces space on smaller screens */
  }
`;

const ImageGallery = () => (
  <GalleryWrapper>
    <StyledPic>
      <StaticImage
        className="img"
        src="../../images/oscp.png"
        width={80}
        quality={95}
        layout="fixed"
        formats={['AUTO', 'WEBP', 'AVIF', 'jpg']}
        alt="OSCP"
      />
    </StyledPic>

    <StyledPic>
      <StaticImage
        className="img"
        src="../../images/crtp.png"
        width={80}
        quality={95}
        layout="fixed"
        formats={['AUTO', 'WEBP', 'AVIF', 'jpg']}
        alt="Cert 1"
      />
    </StyledPic>
  </GalleryWrapper>
);

const About = () => {
  const revealContainer = useRef(null);
  const prefersReducedMotion = usePrefersReducedMotion();

  useEffect(() => {
    if (prefersReducedMotion) {
      return;
    }

    sr.reveal(revealContainer.current, srConfig());
  }, []);

  const skills = [
    'Offensive Security',
    'Network assessment',
    'Web application assessment',
    'Penetration Testing',
    'Red-Teaming',
    'Burpsuite',
  ];

  return (
    <StyledAboutSection id="about" ref={revealContainer}>
      <h2 className="numbered-heading">About Me</h2>

      <div className="inner">
        <StyledText>
          <div>
            <p>
              Hello! My name is Daniel, and currently I work full-time as a Product Security Manager
              at <a href="https://pentest-tools.com/">Pentest-Tools.com</a>, based in Bucharest,
              Romania.
            </p>

            <p>
              I specialize in auditing network infrastructures and web applications for
              vulnerabilities, delivering comprehensive vulnerability assessments and penetration
              test reports. My goal is to help organizations enhance their security posture against
              external threats and reduce the risk posed by attackers.
            </p>
            <p>
              I am{' '}
              <a href="https://www.credly.com/badges/255bd576-6711-4bc7-90ee-627ff28e4f6c">
                OSCP certified
              </a>{' '}
              and{' '}
              <a href="https://www.credential.net/78f8fa8e-9692-4076-92ab-80de07ea5869#acc.6NXb3Ym2">
                CRTP certified
              </a>{' '}
              .
            </p>
            {/* <StyledPic>
              <div>
                <StaticImage
                  className="img"
                  src="../../images/oscp.png"
                  width={80}
                  quality={95}
                  lin
                  layout="fixed"
                  formats={['AUTO', 'WEBP', 'AVIF', 'jpg']}
                  alt="Headshot"
                />
                <StaticImage
                  className="img"
                  src="../../images/crtp.png"
                  width={80}
                  quality={95}
                  lin
                  layout="fixed"
                  formats={['AUTO', 'WEBP', 'AVIF', 'jpg']}
                  alt="Headshot"
                />
              </div>
            </StyledPic> */}
            <ImageGallery />
            <br></br>
            <p>
              I am one of the cybersecurity{' '}
              <a href="https://dnsc.ro/vezi/document/lista-aasc-pf-la-data-de-11-06-2024">
                auditors
              </a>{' '}
              approved by the National Cyber Security Directorate (DNSC).
            </p>

            <p>
              I hold both a bachelor's and a master's degree in computer engineering from the{' '}
              <a href="https://upb.ro/">University Politehnica of Bucharest</a>.
            </p>

            <p>Here are some of the technologies and concepts I work with:</p>
          </div>

          <ul className="skills-list">
            {skills && skills.map((skill, i) => <li key={i}>{skill}</li>)}
          </ul>
        </StyledText>

        <StyledPic>
          <div className="wrapper">
            <StaticImage
              className="img"
              src="../../images/daniel.jpg"
              width={500}
              quality={95}
              formats={['AUTO', 'WEBP', 'AVIF', 'jpg']}
              alt="Headshot"
            />
          </div>
        </StyledPic>
      </div>
    </StyledAboutSection>
  );
};

export default About;
